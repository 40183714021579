jQuery(function() {
    FastClick.attach(document.body);
});

jQuery(document).ready(function($) {

    // clear and restore form input values
    var el = $('input[type=text], input[type=email], textarea, input.header-search');
    el.focus(function(e) {
        if (e.target.value === e.target.defaultValue)
            e.target.value = '';
    });
    el.blur(function(e) {
        if (e.target.value === '')
            e.target.value = e.target.defaultValue;
    });

    $('.menu-toggle').click(function(){
        $(this).toggleClass('active');
        $('nav ul').toggleClass('active');
        $('.header').toggleClass('active');
    });

    $('nav .ul1 > li').click(function(){$(this).toggleClass('active');});
});
